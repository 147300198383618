// Libraries
import React from 'react';

// Components
import LayoutContainer from 'marketing/containers/LayoutContainer';
import Hero from 'marketing/components/Hero';
import CategoryPrizeGrid from 'marketing/components/CategoryPrizeGrid';
import SupplierBlock from 'marketing/components/SupplierBlock';
import CategoryPrizeTile from 'marketing/components/CategoryPrizeTile';

// Styles
import { layoutContainer } from './styles.module.css';

export default (data) => {
  const {
    heading,
    subheading,
    heroImage,
    featurePrizeHeading,
    featurePrizeSubheading,
    featurePrizeImage,
    featurePrizeSupplierLogo,
    categoryBlocks,
    singleSupplierLogo,
    singleSupplierText,
  } = data.pageContext.data;

  const prizeGroups = [];
  let prizeGroup = [];

  if (categoryBlocks) {
    categoryBlocks.forEach((element, idx, array) => {
      if (element.blockType === 'prize_block') {
        prizeGroup.push(element);

        if (idx === array.length - 1) {
          prizeGroups.push(prizeGroup);
        }
      } else if (element.blockType === 'cash_block') {
        if (prizeGroup.length) {
          prizeGroups.push(prizeGroup);
          prizeGroup = [];
        }
        prizeGroup.push(element);
        prizeGroups.push(prizeGroup);
        prizeGroup = [];
      } else if (prizeGroup.length) {
        prizeGroups.push(prizeGroup);
        prizeGroup = [];
      }
    });
  }

  return (
    <LayoutContainer
      includeOrderTicketsCta
      includeWinnersLink={data.pageContext.winnersLink}
    >

      {(subheading || heading || heroImage)
      && (
      <Hero
        subHeading={subheading}
        mainHeading={heading}
        backgroundUrl={heroImage}
      />
      )}

      <div className="u-text-align-center theme-background-primary l-padding-bottom">

        <div className={`${layoutContainer} l-padding-top`}>
          {(featurePrizeHeading || featurePrizeSubheading || featurePrizeImage)
          && (
          <CategoryPrizeTile
            heading={featurePrizeHeading}
            subHeading={featurePrizeSubheading}
            prizeImage={featurePrizeImage}
            supplierLogo={featurePrizeSupplierLogo}
            featured
          />
          )}
        </div>
        {(prizeGroups.length > 0)
        && (
          <div className={layoutContainer}>
            <CategoryPrizeGrid prizeGroups={prizeGroups} />
          </div>
        )}
      </div>
      {(singleSupplierLogo || singleSupplierText)
      && (
      <div className={`${layoutContainer} l-padding u-text-align-center`}>
        <div className="l-col-8 l-col-offset-2">
          <SupplierBlock logo={singleSupplierLogo} text={singleSupplierText} />
        </div>
      </div>
      )}

    </LayoutContainer>
  );
};
