// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styling
import { supplier, supplierLogo, supplierText } from './styles.module.css';

const SupplierBlock = ({ logo, text }) => (
  <div className={supplier}>
    {logo && <img className={supplierLogo} src={logo} alt="" />}

    {text && (
      <div
        className={`${supplierText} theme-color-primary`}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    )}
  </div>
);

SupplierBlock.propTypes = {
  logo: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default SupplierBlock;
