// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import CategoryPrizeTile from 'marketing/components/CategoryPrizeTile';

const processContainerClasses = (row) => {
  const defaultClasses = 'category-prize__container l-padding-top';
  switch (row) {
    case row[0].blockType === 'cash_block':
      return '';
    case row.length === 3:
      return `${defaultClasses} category-prize__container--3up`;
    case row.length === 4:
      return `${defaultClasses} category-prize__container--4up`;
    case row.length >= 4:
      return `${defaultClasses} category-prize__container--5plus`;
    default:
      return defaultClasses;
  }
};

const generatePrizeTiles = row => row.map(
  (
    { blockType, heading, subheading, prizeImage, supplierName, grouping },
    prizeIndex,
  ) => (
    <CategoryPrizeTile
      blockType={blockType}
      heading={heading}
      subHeading={subheading}
      prizeImage={prizeImage}
      supplierName={supplierName}
      grouping={prizeIndex < row.length - 1 ? grouping : undefined}
      key={prizeIndex}
    />
  ),
);

const CategoryPrizeGrid = ({ prizeGroups }) => (
  <div>
    {prizeGroups.map((row, rowIndex) => {
      const containerClasses = processContainerClasses(row);

      return (
        <div className={containerClasses} key={rowIndex}>
          {generatePrizeTiles(row)}
        </div>
      );
    })}
  </div>
);

CategoryPrizeGrid.propTypes = {
  prizeGroups: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        blockType: PropTypes.string,
        grouping: PropTypes.string,
        heading: PropTypes.string,
        prizeImage: PropTypes.string,
        subheading: PropTypes.string,
        supplierName: PropTypes.string,
      }),
    ),
  ),
};

CategoryPrizeGrid.defaultProps = {
  prizeGroups: [],
};

export default CategoryPrizeGrid;
